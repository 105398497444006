import React from "react";
import { Breakingbad } from "./components/Breakingbad";
import Header from "./components/ui/Header";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div>
      <Header />
      <div className="TestA">
        <Link to="/BreakingBad">
          <img
            src="https://m.media-amazon.com/images/I/719eTHjzWxL._AC_SL1000_.jpg"
            alt="IMG BD"
          />
        </Link>

        <Link to="/GameOfthrones">
         
          <img
            src="https://i.ibb.co/3sWHWsv/oie-nwp-Qjm-Qyb-D4-C.jpg"
            alt="IMG GOT"
          />{" "}
        </Link>


        <Link to="/Chouflihal">
         
          <img
            src="https://i.ibb.co/KwbFBvR/oie-p-JTfx41-HSOj-N.png"
            alt="IMG Choufli hal"
          /> 
        </Link>
      </div>
    </div>
  );
};
