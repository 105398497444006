import React, { useState, useEffect } from "react";
import axios from "axios";
import ChouflihalCharactersGrid from "./characters/ChouflihalCharactersGrid";
import { HeaderChouflihal } from "./ui/HeaderChouflihal";

export const Chouflihal = () => {

    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState([true]);
 
    useEffect(() => {
        const fetchItems = async () => {
        
           const result = await axios(`https://mocki.io/v1/0c99c261-62ae-4b31-8ae7-626b2facda63`)
          console.log(result.data)  
          setItems(result.data)
          setIsLoading(false)
        }
    
        fetchItems()
      },[]);
  return (
    <div className="Conatiner">
      <HeaderChouflihal/>
     <ChouflihalCharactersGrid isLoading={isLoading} items={items}/>

  </div>
     
  )
}
