import React from 'react'
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";
export const HeaderBD = () => {
  return (
    <Link to="/"> <header className="center">
    <img src={logo} alt='logo'/>
  </header></Link>
  )
}
