import React from "react";
import ChouflihalCharacterItem from "./ChouflihalCharacterItem";
import {Spinnerchouflihal} from "../ui/Spinnerchouflihal"
import { InfinitySpin } from 'react-loader-spinner'

const ChouflihalCharactersGrid = ({items, isLoading}) => {
  return isLoading ? (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"80vw",height:"50vh"}}><InfinitySpin 
  width='200'
  color="#FF4D00"
/></div>
    //  <Spinnerchouflihal />
     
     )
    // <h1>isLoading...</h1>
   
  : (
    <section className="cards">
      {items.map((item) => (
        <ChouflihalCharacterItem key={item.id} item={item} > </ChouflihalCharacterItem>
      ))}
    </section>
  )
};

export default ChouflihalCharactersGrid;
