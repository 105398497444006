import "./App.css";
import React  from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "./Home";
import { Breakingbad } from "./components/Breakingbad";
import { Got } from "./components/Got";
import { Chouflihal } from './components/Chouflihal';
import { InfinitySpin } from 'react-loader-spinner'

const App = () => {
  return (
    <>
      <div className="container">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path={"/BreakingBad"} element={<Breakingbad />} />
          <Route exact path={"/GameOfthrones"} element={<Got />} />
          <Route exact path={"/chouflihal"} element={<Chouflihal />} />

          <Route exact path={"/*"} element={<Home />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
