import React, { useState } from "react";

const Search = ({getQuery}) => {
 const [text, setText]= useState('')
const onChangeSearchValue = (q) => {
    setText(q)
    getQuery(q)
}

  return (
    <section className="search">
      <form>
        <input type="text" className="form-search" 
        placeholder="Search Characters"  
        value={text}
        // onChange={(e) =>setText(e.target.value)}
        onChange={(e) =>onChangeSearchValue(e.target.value)}
        autoFocus/>
      </form>
    </section>
  );
};

export default Search;
