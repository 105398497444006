import React, { useState, useEffect } from "react";
import axios from "axios";
import CharactersGrid from "./characters/CharactersGrid";
import Search from "./ui/Search";
import { HeaderBD } from "./ui/HeaderBD";

export const Breakingbad = () => {

    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState([true]);
    const [query, setQuery] = useState('');
    useEffect(() => {
        const fetchItems = async () => {
          const result = await axios(`https://www.breakingbadapi.com/api/characters?name=${query}`)
          console.log(result.data)
          setItems(result.data)
          setIsLoading(false)
        }
    
        fetchItems()
      },[query]);
  return (
    <div className="Conatiner">
      <HeaderBD/>
     <Search getQuery={(q)=>setQuery(q)}/>
     <CharactersGrid isLoading={isLoading} items={items}/>

  </div>
     
  )
}
