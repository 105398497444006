import React from "react";
import Logoseries from "../../img/logoseries.png";
import { Link } from "react-router-dom";

const Header = () => {
  return <header className="center">
  <Link to="/"><img  style={{width:'350px'}} src={Logoseries} alt='logo'/>
    </Link>  <p>Choose one of this series</p>
</header>;
};

export default Header;
