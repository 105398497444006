import React from "react";
import CharacterItem from "./CharacterItem";
import { Spinner } from "../ui/Spinner";
import { InfinitySpin } from 'react-loader-spinner'

const CharactersGrid = ({items, isLoading}) => {
  return isLoading ?  (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"80vw",height:"50vh"}}><InfinitySpin 
  width='200'
  color="#4fa94d"
/></div>
    //  <Spinner />
     
     )
    // <h1>isLoading...</h1>
   
  : (
    <section className="cards">
      {items.map((item) => (
        <CharacterItem key={item.char_id} item={item} > </CharacterItem>
      ))}
    </section>
  )
};

export default CharactersGrid;
