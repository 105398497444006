import React from "react";
import GotCharacterItem from "./GotCharacterItem";
import { Spinnergot } from "../ui/Spinnergot";
import { InfinitySpin } from 'react-loader-spinner'

const GotCharactersGrid = ({items, isLoading}) => {
  return isLoading ? (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"80vw",height:"50vh"}}><InfinitySpin 
  width='200'
  color="#4fa94d"
/></div>
    //  <Spinnergot />
     
     )
    // <h1>isLoading...</h1>
   
  : (
    <section className="cards">
      {items.map((item) => (
        <GotCharacterItem key={item.id} item={item} > </GotCharacterItem>
      ))}
    </section>
  )
};

export default GotCharactersGrid;
